import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const Activities = () => {
const location= useLocation();
const categoryName = location.state && location.state.categoreyname;
const [activities,setActivities] = useState([]);
const { id } =useParams();


useEffect(() => {
  axios
    .get(`https://qupon.qa/mahecooperativecollege/api/activity-detail/${id}`)
    .then(function (response) {
      // handle success

      setActivities(response.data.data);
      
     console.log(activities); 
      
    });

}, [id]);







  return (
    <div>
      {/* Inner Banner */}
      <section className="inner-banner">
        <div className="container">
          <ul className="list-unstyled thm-breadcrumb">
            
          </ul>
          <h2 className="inner-banner__title">{categoryName}</h2>
        </div>
      </section>

      {/* About Us Section */}
      <section className="aboutus-one" id="main">
        <div className="container">
          <div className="accordion" id="faq">
          {Object.keys(activities).map((year) => (
                    <div class="card">
                        <div class="card-header" id="faqhead7">
                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq7"
                            aria-expanded="true" aria-controls="faq7">{year}</a>
                        </div>

                        <div id="faq7" class="collapse" aria-labelledby="faqhead7" data-parent="#faq">
                           <div class="card-body">
                                <div class="row">
									{/* <div class="col-lg-12">
										<p>College union organises activities like Arts Festivals, Sports Celebrations like Onam, Christmas, Teachers Day, Independence Day, Republic Day, Service day, Excursions, Observation of various important days and publication of college magazine.</p>
									</div> */}
                  {activities[year].map((item) => (
									<div class="col-lg-6">
										<img src={item.image} alt="" class="img-fluid"/>
									</div>
                  ))}
									{/* <div class="col-lg-6">
										<img src="theme/web/images/activities/college2.jpeg" alt="" class="img-fluid"/>
									</div> */}
								</div>
                            </div>
                        </div>
                    </div>
                    
                    ))}
                   
                 
							
                    
                  
					 
					 
			
					 
					 
					
					 
					 
          </div>
        </div>
      </section>

    </div>
  );
};

export default Activities;
