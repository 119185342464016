import React, { useState } from 'react';
import axios from 'axios';

const Admission = () => {
    const [formData, setFormData] = useState({
        name_of_candidate: '',
        name_of_father: '',
        date_of_birth: '',
        permanent_address: '',
        address_of_communication: '',
        contactNumbber: '',
        alternateNumber: '',
        subject_appeared: '',
        percentage: '',
        filename1: null,
        filename2: null,
      });
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleFileChange = (event) => {
        const { name, files } = event.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: files[0],
        }));
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const form = new FormData();
        for (const key in formData) {
          form.append(key, formData[key]);
        }
    
        try {
          const response = await axios.post('https://qupon.qa/mahecooperativecollege/api/submit-admission-form', form);
          console.log('Form submitted successfully:', response.data);
          // Handle success, show a success message, redirect, etc.
        } catch (error) {
          console.error('Error submitting form:', error);
          // Handle error, show an error message, etc.
        }
      };
    
    return (
        <div>
            <section className="inner-banner">
                <div className="container">
                    <ul className="list-unstyled thm-breadcrumb">
                        
                    </ul>
                    <h2 className="inner-banner__title">STUDENT REGISTRATION FORM</h2>
                </div>
            </section>
        
            <section className="aboutus-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="contact_form mt-40">
                            <form onSubmit={handleSubmit} encType="multipart/form-data">
                  <div className="row">
                                <div class="col-md-12 col-lg-12">
                                    <div class="single_form">
                                    <input
                          type="text"
                          className="form-control"
                          placeholder="Name of the Student"
                          name="name_of_candidate"
                          value={formData.name_of_candidate}
                          onChange={handleChange}
                        />
                                    </div>
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <input type="text" class="form-control" placeholder="Name Of Father" name="name_of_father" value={formData.name_of_father}
                          onChange={handleChange} required=""/>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <input type="DATE" class="form-control" placeholder="Date of birth" name="date_of_birth" value={formData.date_of_birth}
                          onChange={handleChange} required=""/>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <textarea class="form-control" placeholder="Permanent Address" name="permanent_address" value={formData.permanent_address} onChange={handleChange} required=""></textarea>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <textarea class="form-control" placeholder="Address For Communication" name="address_of_communication" value={formData.address_of_communication} onChange={handleChange} required=""></textarea>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <input class="form-control" type="text" placeholder="Mobile Number" name="contactNumbber" value={formData.contactNumbber} onChange={handleChange} required=""/>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                    <div class="single_form">
                                        <input class="form-control" type="text" placeholder="Alternate Number" name="alternateNumber"  value={formData.alternateNumber} onChange={handleChange} required=""/>
                                    </div> 
                                </div>

								<div class="col-md-6 col-lg-6">
                                  <div class="single_form">
                                        <input class="form-control" type="text" placeholder="Name of the subject appeared :" name="subject_appeared" value={formData.subject_appeared} onChange={handleChange} required=""/>
                                    </div>
                                </div>

								<div class="col-md-6 col-lg-6">
                                  <div class="single_form">
                                        <input class="form-control" type="text" placeholder="Percentage of Marks" name="percentage" value={formData.percentage} onChange={handleChange} required=""/>
                                    </div>
                                </div>

								<div class="col-md-12">
                                    <div class="single_form">
                                        <h6>Upload:</h6>
                                    </div>
                                </div>

								<div class="col-md-12">
                                  <div class="file" style={{ paddingTop: '30px' }}>
									  <p><b>Degree Mark list :</b><br/>
									  Only jpeg, jpg, png, pdf, doc, docx, gif, JPG &amp; JPEG allowed. Not to exceed 2MB in size. 
									  </p>
                                      <input
                          type="file"
                          className="form-control"
                          id="myFile"
                          name="filename1"
                          onChange={handleFileChange}
                          required
                        />
                                  </div>
                                </div>

								<div class="col-md-12">
                                  <div class="file" style={{ paddingTop: '30px' }}>
									  <p><b>Degree Certificate / Provisional certificate:</b><br/>
									  Only jpeg, jpg, png, pdf, doc, docx, gif, JPG &amp; JPEG allowed. Not to exceed 2MB in size. 
									  </p>
                                      <input
                          type="file"
                          className="form-control"
                          id="myFile"
                          name="filename2"
                          onChange={handleFileChange}
                          required
                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                      <div className="single_form">
                        <button className="main-btn thm-btn" style={{ background: 'rgba(49, 80, 112, 0.6)' }}>Submit</button>
                        <br />
                        <span style={{ color: 'red' }}>
                          <b>Please wait for sometime after pressing submit button...Do not resubmit or press refresh button. File uploading may take sometime</b>
                        </span>
                        </div>
                      </div>
                            </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Admission;
