import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TestimonialCarousel = () => {
  const [Testimonials, setTestimonials] = useState([]);
  useEffect(() => {
      axios.get('https://mahecooperativecollege.com/admin/api/testimonials')
      .then(response => {
      
        setTestimonials(response.data.data);
       console.log(response.data.data) 
      })
      .catch(error => {
        console.error('Error fetching testimonials images:', error);
      });

      
  }, []);
  return (
    <div className="testimoniyalcaro">
    <div id="carouselExampleControlsnew" className="carousel slide text-center carousel-dark" data-ride="carousel">
      {/* Carousel Inner */}
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div className="carousel-inner">
        {/* Carousel Items */}
        {/* Replace the carousel items with JSX */}
        {/* First Carousel Item */}
  


    {Testimonials.map((item, index) => ( 
    <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
      <img class="rounded-circle shadow-1-strong mb-4"
        src={item.image} alt="avatar"
        style={{ width: '150px' }} />
      <div class="row d-flex justify-content-center">
        <div class="col-lg-8">
          <h5 class="mb-3">{item.name}</h5>
          
          <p class="text-muted">
            <i class="fas fa-quote-left pe-2"></i>
            {item.description}
          </p>
        </div>
      </div>
    </div>
 ))}


      </div>

      {/* Carousel Controls */}
      <button class="carousel-control-prev" type="button" data-mdb-target="#carouselExampleControlsnew"
    data-mdb-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden"></span>
  </button>
  <button class="carousel-control-next" type="button" data-mdb-target="#carouselExampleControlsnew"
    data-mdb-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden"></span>
  </button>
    </div></div>
  );
};

export default TestimonialCarousel;