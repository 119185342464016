import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Links = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://mahecooperativecollege.com/admin/api/usefullink')
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  //const indexOfLastItem = currentPage * itemsPerPage;
 // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <section className="inner-banner">
        <div class="container">
          <ul class="list-unstyled thm-breadcrumb">
            
          </ul>
          <h2 class="inner-banner__title">USEFULLINK</h2>
        </div>
      </section>

      <section className="aboutus-one">
        <div className="container">
          <div className="row">
            
                  {data.map((item) => ( 
                    <div className="col-lg-3 col-sm-12">
                      <div className="nonteachingstaf text-center">
                      <img src={item.image} alt='' width="200" height="80" className='img-fluid' />
                      <br></br>
                      
                      </div> 
                        <div className="connenturl  text-center ">
                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                            <p className='text-dark  text-center  text-capitalize fw-bold'> {item.tiile}</p>
                          </a> 
                        </div>
                      </div>
                  ))}
            
          </div>
          
      </div>
      
        <div className="container d-none  text-center">
            <div className="row text-center">
                <div className="col-lg-12 col-sm-12 text-center nonteachingstafcontent">
                  <div className="pagination text-center">
                    {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, i) => (
                      <span type="button" className='btnpagination' key={i} onClick={() => paginate(i + 1)}>
                        {i + 1}
                      </span>
                    ))}
                  </div>
                </div>
            </div>
        </div>  
       </section>             
    </div>
  );
};

export default Links;
