import React, {useState,useEffect} from "react";
import axios from "axios";
const Teachingstaff = () => {

    const [principal,setPrincipal]=useState([]);
    const [hod,setHod]=useState([]);
    const [asst_professor,setAsst_professor]=useState([]);

    useEffect(()=>{
        axios.get('https://mahecooperativecollege.com/admin/api/teachingstaffs')
  .then(function (response) {
    // handle success
    
    setPrincipal(response.data.data.principal);
    setHod(response.data.data.hod);
    setAsst_professor(response.data.data.asst_professor);
    
    
  })

        
    },[])

    return (
      <div className="teachingstaff">
        
        <section class="inner-banner">

<div class="container">

    <ul class="list-unstyled thm-breadcrumb">

        

    </ul>

    <h2 class="inner-banner__title">Teaching Staff</h2>

</div>

</section>





<section class="team-one team-page justify-content-center">

<div class="container">

					
				
    <div className="row team-pageview">
    
        {principal.map((obj,index)=>{
            return(
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">

                <div className="team-one__single">
    
                    <div class="team-one__image">
    
                        <img src={obj.photo} alt="" className="img-responsive"/>
    
                    </div>
    
                    <div class="team-one__content">
    
                        <h2 class="team-one__name"><a href="team-details.html">{obj.name}</a></h2>
    
                        
    
                        <p class="team-one__designation">{obj.designation}</p>
    
                        <p class="team-one__text">{obj.qualification}</p><br/>
                        
                        {obj.cv ? (
                        <a target="_blank" href={obj.cv}>View CV</a>
                        ) : (
                            <p></p>
                          )}
                        
                    </div>
    
                   
                </div>
    
            </div>
            )
        }
        )}
        

        

    </div>
    
    <div className="row team-pageview" >
        {hod.map((obj,index)=>{
            return(
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">

                <div className="team-one__single">
    
                    <div class="team-one__image">
    
                        <img src={obj.photo} alt="" className="img-responsive"/>
    
                    </div>
    
                    <div class="team-one__content">
    
                        <h2 class="team-one__name"><a href="team-details.html">{obj.name}</a></h2>
    
                        
    
                        <p class="team-one__designation">{obj.designation}</p>
    
                        <p class="team-one__text">{obj.qualification}</p><br/>
    
                        {obj.cv ? (
                        <a target="_blank" href={obj.cv}>View CV</a>
                        ) : (
                            <p></p>
                          )}
    
                    </div>
    
                   
                </div>
    
            </div>
            )
        }
        )}
        

        

    </div>
    
    <div className="row team-pageview">
        {asst_professor.map((obj,index)=>{
            return(
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">

                <div className="team-one__single">
    
                    <div class="team-one__image">
    
                        <img src={obj.photo} alt="" className="img-responsive"/>
    
                    </div>
    
                    <div class="team-one__content">
    
                        <h2 class="team-one__name"><a href="team-details.html">{obj.name}</a></h2>
    
                        
    
                        <p class="team-one__designation">{obj.designation}</p>
    
                        <p class="team-one__text">{obj.qualification}</p><br/>
                        {obj.cv ? (
                        <a target="_blank" href={obj.cv}>View CV</a>
                        ) : (
                            <p></p>
                          )}
                    </div>
    
                   
                </div>
    
            </div>
            )
        }
        )}
        

        

    </div>

</div>

</section>







      </div>
    );
  }
  
  export default Teachingstaff;