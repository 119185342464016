import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Iqac = () => {
  const [documents, setDocuments] = useState([]);
  const [heading,setHeading]=useState('');
  const { type } = useParams();

  useEffect(() => {
    axios
      .get(`https://mahecooperativecollege.com/admin/api/document-list/${type}`)
      .then(function (response) {
        if (response.data && response.data.data) {
          setDocuments(response.data.data.list);
          setHeading(response.data.data.head);
          console.log(response.data.data.list);
        }
      })
      .catch(function (error) {
        console.error('Error fetching documents:', error);
      });
  }, [type]);

  return (
    <div className="Iqac">
      {/* ... your banner section */}
      <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">{heading}</h2>
            </div>
        </section>
      <section className="aboutus-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 downloads">
              <table className="table table-bordered">
                <tbody>
                  {documents.length > 0 ? (
                    documents.map((obj, index) => (
                      <tr key={index}>
                        <td>
                          <a target="_blank" rel="noopener noreferrer" href={obj.file}>
                            <i className="fa fa-file-pdf" aria-hidden="true"></i> {obj.title}
                          </a>
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <a target="_blank" rel="noopener noreferrer" download href={obj.file}>
                            <i className="fa fa-download" aria-hidden="true"></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No documents available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Iqac;