import React from "react";

const Footer = () => {
    return (
      <div className="footer">
        <div class="page-wrapper">
        <footer class="site-footer">
            <div class="site-footer__upper">
                <div class="container">
				    
                    <div class="row">
                        <div class="col-xl-2 col-lg-6 col-sm-12">
                            <div class="footer-widget footer-widget__link">
                                <h2 class="footer-widget__title">OUR COURSES</h2>
								<p><b>B Ed in</b></p>
                                <div class="footer-widget__link-wrap">
                                    <ul class="list-unstyled footer-widget__link-list">
                                       <li><a href="courses.html"> PHYSICAL SCIENCE</a></li>  
                                       <li><a href="courses.html"> SOCIAL STUDIES</a></li>  
                                       <li><a href="courses.html"> BIOLOGICAL SCIENCE</a></li>
                                       <li><a href="courses.html"> ENGLISH</a></li>  
                                       <li><a href="courses.html"> MATHEMATICS</a></li>  
                                       <li><a href="courses.html"> COMMERCE</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
						<div class="col-xl-4 col-lg-6 col-sm-12">
                            <div class="footer-widget footer-widget__link">
                                <h2 class="footer-widget__title">QUICK LINKS</h2>
                                <div class="footer-widget__link-wrap">
									<ul class="list-unstyled footer-widget__link-list">
                                        <li><a href="/">HOME</a></li>
										<li><a href="/about">About Us</a></li>
										<li><a href="/principal">Principal Note</a></li>
									    <li><a href="/courses">COURSES</a></li>
										<li><a href="/teaching-staff">Teaching Staff</a></li>
										<li><a href="/nonteaching-staff">Non-Teaching Staff</a></li>
										<li><a href="/facilities">FACILITIES</a></li>
										
										
                                
                                    </ul>
									<ul class="list-unstyled footer-widget__link-list">
                                        <li><a href="/iqac">IQAC</a></li>
										<li><a href="/naac">NAAC</a></li>
										<li><a href="/ncte">NCTC</a></li>
										<li><a href="/activities">ACTIVITIES</a></li>
										<li><a href="/events">EVENTS</a></li>
										<li><a href="/usefullinks">USEFUL LINKS</a></li>
										<li><a href="/admission_form" target="_blank">ONLINE ADMISSION</a></li>
										
          
                                    </ul>
                                </div>
                            </div>
                        </div>
						
						<div class="col-xl-3 col-lg-6 col-sm-12">
                            <div class="footer-widget footer-widget__link">
                                <h2 class="footer-widget__title">USEFUL LINKS</h2>
                                <div class="footer-widget__link-wrap">
                                    <ul class="list-unstyled footer-widget__link-list">
                                      <li><a href="http://www.pondiuni.edu.in/" target="_blank">Pondicherry University</a></li>
                                      <li><a href="http://www.pondiuni.edu.in/unitary-schools/school-education" target="_blank">Higher Education Department Pondicherry</a></li>
									  <li><a href="https://www.ncte.gov.in/Website/b_ed_pondicherry.aspx" target="_blank">NCTE</a></li>
                                        <li><a href="https://www.ugc.ac.in/centralniversitylist.aspx?id=27&amp;Unitype=1" target="_blank">UGC</a></li>
									    <li><a href="https://mahe.gov.in/" target="_blank">CEO Office, Mahe</a></li>                
									    <li><a href="http://mahecooperativecollege.com/mcche/index.php" target="_blank">Mahe Co-Operative College of Higher Education and Technology</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-sm-12">
                            <div class="footer-widget footer-widget__about">
                                <h2 class="footer-widget__title">GET IN TOUCH</h2>
                                
                                <div class="footer-widget__btn-block">
                                    <p>MAHE CO-OPERATIVE COLLEGE OF TEACHER EDUCATION</p>
                                    <p>(A Unit of The Mahe Co-operative Centre for Information Technology Ltd. No. 689)
(Affiliated to Pondicherry University and Recognized by NCTE)
Cemetery Road, Manjakkal, MAHE – 673310, Puducherry State
</p>
                                    <p><a href="tel:9447193371"><i class="fa fa-mobile">  </i> +91 9447193371</a></p>
                                    <p><a href="tel:0490 2333975"><i class="fa fa-phone">  </i>  0490 2333975</a></p>
                                    <p><a href="mailto:mcctemahe@gmail.com"><i class="fa fa-envelope">  </i> mcctemahe@gmail.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="site-footer__bottom">
                <div class="container">
                    <p class="site-footer__copy">&copy; COPYRIGHT @ 2023. MAHE . ALL RIGHTS RESERVED. </p>
                    <div class="site-footer__social">
                        <a href="#" data-target="html" class="scroll-to-target site-footer__scroll-top"><i class="kipso-icon-top-arrow"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-facebook-square"></i></a>
                        <a href="#"><i class="fab fa-pinterest-p"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                    </div>
                    
                </div>
            </div>
        </footer>
        </div>
      </div>
    );
  }
  
  export default Footer;