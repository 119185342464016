import React from "react";

const Aboutus = () => {
    return (
      <div className="Aboutus">
        <section class="inner-banner">

<div class="container">

    <ul class="list-unstyled thm-breadcrumb">

        

    </ul>

    <h2 class="inner-banner__title">About</h2>

</div>

</section>



<section class="aboutus-one">

<div class="container">

    <div class="row">

        <div class="col-xl-12 col-lg-12 col-sm-12">

            <h2>ABOUT MCCTE</h2>

            <p class="pt-2">Mahe cooperative college of teacher education, Mahe was established in 2005 under the management of the co-operative society, The Mahe co.operative Center for Information technology. The society runs another IF and PG college, The Mahe co.operative College of higher education and technology, Palloor. The teacher education institution is a private institution affiliated to Pondicherry University. The institution has been consistently showing good academic records for teacher trainees ever since its inception. The team work of the management, staff and students raised the level of the college to its excellence. At present the college offers 6 optional subjects like English, Social Science, Physical science, Biological Science, Mathematics and Commerce. The total intake of the students is 100.</p>

        </div>

    </div>

    <div class="row pt-5">	

        <div class="col-xl-2 col-lg-2 col-sm-12 pt-1">

            <img src="theme/web/images/aboutlogo.jpg" alt="" width="100%" class="img-fluid" style={{border: '1px solid #073d771f', borderRadius: 10}} />

        </div>

        <div class="col-xl-10 col-lg-10 col-sm-12">

            <h3>COLLEGE EMBLEM</h3>

            <p>The emblem of the college bears a lighted lamp, an open book and hand shaking. The lighted lamp and book represents wisdom and knowledge. The light that emanates from the lamp fills the whole space. The clubbed hands shows the spirit of unity and cooperation.</p>

            

            <h3 class="pt-2">OUR MOTTO</h3>

            <p>"Sradhaavan Labhathe Jnanam" ..The quotation is taken from Bhagavat GeÃ¨tha which means A faithful person with attention gains supreme knowledge.</p>

        </div>

    </div>

    <div class="row pt-5">	

        <div class="col-lg-12 col-sm-12 visionblock">

          <div class="visionhead">VISION OF THE COLLEGE</div>  

          <p class="pt-3">"To create and disseminate knowledge among youth so as to equip them in appropriate skills for achieving sustainable development"</p>

        </div>

    </div>

    

    <div class="row pt-5">	

        <div class="col-lg-12 col-sm-12 visionblock">

          <div class="visionhead">MISSION OF THE COLLEGE</div>  

              <ul class="pt-2">

                 <li>To persue excellence in education by imparting professional skills among youth.</li>

                 <li>To impart training in technology to seek advancement in the fields of teaching and learning.</li>

                 <li>To  develop models of sustainable development in the community.</li>

                 <li>To develop technology based tools for the benefit of the larger professional fraternity.</li>

                 <li> To mould technologically superior and ethically strong teachers.</li>

             </ul>

        </div>

    </div>

</div>

</section>


      </div>
    );
  }
  
  export default Aboutus;