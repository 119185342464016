import React, { useState, useEffect } from "react";
import axios from "axios";
const Facilities = () => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    axios
      .get("https://mahecooperativecollege.com/admin/api/facilities")
      .then(function(response) {
        // handle success

        setFacilities(response.data.data);
        console.log(response.data.data);
      });
  }, []);

  return (
    <div className="facilities">
      <section class="inner-banner">
        <div class="container">
          <ul class="list-unstyled thm-breadcrumb"></ul>
          <h2 class="inner-banner__title">Facilities</h2>
        </div>
      </section>

      <section class="aboutus-one" id="main">
        <div class="container">
          <div class="accordion" id="faq">
            {facilities.map((obj, index) => {
              return (
                <div class="card">
                  <div class="card-header" id="faqhead6">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target={`#faq6${index}`}
                      aria-expanded="true"
                      aria-controls="faq6"
                    >
                      {obj.name}
                    </a>
                  </div>

                  <div
                    id={`faq6${index}`}
                    className={`collapse ${index === 0 ? "show" : ""}`}
                    aria-labelledby="faqhead6"
                    data-parent="#faq"
                  >
                    <div class="card-body">
                      <div class="row">
                        {obj.images.map((img, index) => {
                          return (
                            <div class="col-lg-6">
                              <img src={img} alt="" class="img-fluid" />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Facilities;
