import React from 'react';

const Courses = () => {
  return (
    <div className="courses">
          <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">Courses</h2>
            </div>
        </section>
		
		
		
	    <section class="aboutus-one">
            <div class="container-fluid">
				<div class="row">
					<div class="col-xl-12 cta-one__btn-block  col-lg-12 col-sm-12 text-center">
						<h3>MCCTE Offers B Ed. in The Following Subjects</h3>
					</div>
				</div>
				<div class="row pt-3">	<div class="col-lg-1"></div>
					<div class="col-xl-2 col-lg-2 col-sm-12 p-1">
						<div class="course-detaials">
							<h3>PHYSICAL SCIENCE</h3>
							
							<ul>
								<li>Physics</li>
								<li>Chemistry</li>
								 <li>Electronics</li>
								  <li>Biochemistry</li>
							</ul>
						</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-sm-12 p-1">
					<div class="course-detaials">
						<h3>SOCIAL STUDIES</h3>
						
						    <ul>
								<li>Historical Studies</li>
								<li>Psychology</li>
								<li>Sociology</li>
								<li>Philosophy</li>
							</ul>
					</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-sm-12 p-1">
					<div class="course-detaials">
						<h3>BIOLOGICAL SCIENCE</h3>
						
						<ul>
								<li>Botany/Plant Science</li> 
								<li>Zoology/Animal Science</li> 
								<li>Micro-Biology</li>
								<li>Applied Micro-Biology</li> 
								<li>Marine Biology</li>
								<li> Biotechnology including plant bio-technology.</li>
						</ul>
					</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-sm-12 p-1">
					<div class="course-detaials">
						<h3>ENGLISH</h3>
						
						<ul>
								<li>English</li> <li>Functional English</li> <li>English literateur and English for career</li> 

						</ul>
					</div>
					</div>
					<div class="col-xl-2 col-lg-2 col-sm-12 p-1">
					<div class="course-detaials">
						<h3>MATHEMATICS</h3>
						
						<ul>
								<li>Mathematics</li> 

						</ul>
					</div>
					</div>
					<div class="col-lg-1"></div>
                </div>
				<div class="row pt-3">	
					<div class="col-lg-1"></div>
					<div class="col-lg-11">
						<small><i>*One should have passed both Bachelors &amp; Masters degree in the same subject<br/>
								 *One should have passed both Bachelors &amp; Masters degree in the same subject
							</i></small>
					</div>
				</div>
			</div>
			<div class="container pt-5">	
				<div class="row">
					<div class="col-xl-12 cta-one__btn-block  col-lg-12 col-sm-12 text-center">
						<h3>Eligibility For 2 Year B Ed.  Course</h3>
						<p class=" text-center">The total in take is of 2 units of 50 students each.</p>
					</div>
				</div>
				
				<div class="row p-0">
					<div class="col-xl-12 col-lg-12 col-sm-12 text-left p-0">
						<ul class="list-groups">
							<li>Candidate should have scored a minimum of 50% Marks in Part III(Main+Sub) of the Bachelor’s degree course or in the Masters degree course.  Both Bachelor’s degree and Masters degree should be in the same subject.  For commerce optional, candidates should have passed M.Com with a minimum of 50%</li>
							<li> A minimum of 40% marks for OBC, MBC, SC/ST and Physically Challenged candidates.</li>
							<li> There is no upper age limit for B.Ed admission.</li>
							<li> College is provided with infrastructure facilities like Computer Lab.  Psychology Lab, Science Lab, Edn. Technology Lab, Work experience Lab and Sports and health Edn. Lab.</li>
							<li>Students are provided with opportunities to develop their personality Co-Curricular activities like seminars, debates, quiz, elocution, essay writing etc. are conducted periodically.</li>
							<li> The Physical Education and Health Education classes are conducting effectively.</li>
							<li> Extension and community activities are carried out by students of the college.</li>
							<li> Periodic internal examinations are conducted and progress of the students are reported to parents.</li>
							<li> An efficient PTA (Parent-Teacher Association) is functioning in the College.</li>
							<li> Hostel facilities available for women &amp; men candidates.</li>
							<li> As per the directions of Supreme Court, State Government and University, ragging is strictly prohibited in the Campus.</li>
						</ul>
					</div>
				</div>
			</div>
        </section>  
		
		    

        
    </div>
  );
};

export default Courses;
