import React, {useState,useEffect} from "react";
import axios from "axios";
const Nonteachingstaff = () => {

    const [nonstaff,setNonstaff]=useState([]);

    useEffect(()=>{
        axios.get('https://mahecooperativecollege.com/admin/api/nonteachingstaffs')
  .then(function (response) {
    // handle success
    
    setNonstaff(response.data.data)
    console.log(response.data.data)
  })

        
    },[])

    return (
      <div className="nonteachingstaff">
        
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">Non Teaching Staff</h2>
            </div>
        </section>
		
		 <section class="aboutus-one">
            <div class="container">
				<div class="row">
			        <div class="col-lg-12 col-sm-12 nonteachingstaf">
					  <table class="table table-bordered">
							<thead>
								<tr>
									<th>Name</th>
									<th>Designation</th>
									<th>Qualification</th>
								</tr>
							</thead>
							<tbody>
                            {nonstaff.map((obj,index)=>{
            return(
								<tr>
									<td>{obj.name}</td>
									<td>{obj.designation}</td>
									<td>{obj.qualification}</td>
								</tr>
						)
                    }
                    )}		
								
							</tbody>
					  </table>
                    </div>
                </div>
	        </div>
        </section>







      </div>
    );
  }
  
  export default Nonteachingstaff;