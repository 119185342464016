import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery'; // Import jQuery//
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
//import './Home.css';
import OwlCarousel from 'react-owl-carousel';// Import Owl Carousel
//import 'owl.carousel/dist/assets/owl.carousel.css'; // Import Owl Carousel CSS
//import 'owl.carousel/dist/assets/owl.theme.default.css'; // Import Owl Carousel Default Theme CSS

const Photogallery = () => {
  const [galleryImages, setGalleryImages] = useState([]);
    useEffect(() => {
      
        axios.get('https://mahecooperativecollege.com/admin/api/gallery')
          .then(response => {
          
            setGalleryImages(response.data.data);
           console.log(response.data.data) 
          })
          .catch(error => {
            console.error('Error fetching gallery images:', error);
          });
         
          return () => {
            $('.owl-carousel').trigger('destroy.owl.carousel');
          };
          
      }, []);
  const options = {
    items: 3, // Number of items shown at once
    loop: true, // Infinite loop
    nav: true, // Navigation arrows
    dots:true,
  };

  return (
    <OwlCarousel className="owl-theme" {...options}>
      {galleryImages.map((item, index) => ( 
      <div key={index} className="item">
        <div className="blog-two__single">
          <img src={item.image} alt={`gallery ${index}`} className="img-fluid" />
        </div>
      </div>
      ))}
    </OwlCarousel>
  );
};

export default Photogallery;