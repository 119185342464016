import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

const Newseventsdetail = () => {
  const [event, setEvent] = useState([]);
  const [eventimages, setEventimages] = useState([]);
  const [latestevent, setLatestevent] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://mahecooperativecollege.com/admin/api/events-detail/${id}`)
      .then(function (response) {
        // handle success

        setEvent(response.data.data[0]);
        setEventimages(response.data.data[0].images);
        
        
      });
    axios
      .get("https://qupon.qa/mahecooperativecollege/api/latest-events")
      .then(function (response) {
        // handle success

        setLatestevent(response.data.data);
        
        
      });
  }, [id]);

  return (
    <div className="Ncte">
      <section class="inner-banner">
        <div class="container">
          <ul class="list-unstyled thm-breadcrumb">
            
          </ul>
          <h4 class="inner-banner__title">{event.title}</h4>
        </div>
      </section>

      <section class="blog-details">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="blog-one__single">
                <div className="blog-one__image">
                  <img src={event.image} alt="" />
                </div>
                <div class="blog-one__content text-left">
                  <div class="blog-one__meta pt-5">
                   
                   
                    
                  </div>
                  <h4 class="blog-one__titles">{event.short_description}</h4>
                  <p class="blog-one__text">{event.long_description}</p>

                 


                  
                  <section class="blog-one blog-page">
            <div class="container">
                <div class="row">
                {eventimages.map((img,index)=>{
            return(
                    <div class="col-lg-4 pb-20 mb-20">
                        <div class="blog-one__single">
                            <div class="blog-one__image">
                                <img src={img} alt="" width="500" height="230"/>
                                
                            </div>
                           
                        </div>
                    </div>
                 )
                }
                )}	  
                  
                    
                    
                </div>
                {/* <div class="post-pagination">
                    <a href="#"><i class="fa fa-angle-double-left"></i></a>
                    <a class="active" href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#"><i class="fa fa-angle-double-right"></i></a>
                </div> */}
            </div>
        </section>
        


                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="sidebar">
                <div class="sidebar__single sidebar__post">
                  <h3 class="sidebar__title">Latest Events</h3>
                  <div class="sidebar__post-wrap">

                  {latestevent.map((obj,index)=>{
            return(
                    <div class="sidebar__post__single">
                      <div class="sidebar__post-image">
                        <div class="inner-block">
                          <img
                            src={obj.images}
                            alt="Awesome Image"
                          />
                        </div>
                      </div>
                      <div class="sidebar__post-content">
                        <h4 class="sidebar__post-title">
                        <Link to={`/events-detail/${obj.id}`}>
                          {obj.short_description}
                          </Link>
                        </h4>
                      </div>
                    </div>
                    
                    )
                }
                )}	
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Newseventsdetail;
