import React from 'react';

const Downloads = () => {
  return (
	  <div>
  <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">Downloads</h2>
            </div>
        </section>
		
		 <section class="aboutus-one">
            <div class="container">
				<div class="row">
			        <div class="col-lg-12 col-sm-12 downloads">
					  <table class="table table-bordered">
						<tbody>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/Provisional_Affiliation.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i> Conditional Provisional Affiliation with Pondicherry University for B Ed. course(2Years)</a></td>
								<td style={{ textAlign:'center' }}><a target="_blank" download href="theme/web/images/downloads/Provisional_Affiliation.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/Mahe_co-college_of_Techer_Educaton,_Mahe_new_2022.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>  List of students for the academic year 2021-22(2nd Year)</a></td>
								<td style={{ textAlign: 'center'}}>
  <a
    target="_blank"
    download
    href="theme/web/images/downloads/Mahe_co-college_of_Techer_Educaton,_Mahe_new_2022.pdf"
  >
    <i className="fa fa-download" aria-hidden="true"></i>
  </a>
</td></tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/completion_certificate.pdf">     <i class="fa fa-file-pdf" aria-hidden="true"> </i> Completion Certificate</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/completion_certificate.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/antiragging.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>      Anti ragging meeting minutes</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/antiragging.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/NCTECorrigendum.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i>   NCTE ORDER Corrigendum</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/NCTECorrigendum.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/RandCcombinedpdf.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>   Receipt and Payments upto March 2022(2021-22)	</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/RandCcombinedpdf.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/AffiliationOrder-21-22.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>    MCCTE University affiliation order 2021 -2022	</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/AffiliationOrder-21-22.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/Order_26.07.22.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i>   Affiliation Order for B Ed course 2021-22</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/Order_26.07.22.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/declaration_list_merged.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i>    List of students for the academic year 2022-23(1st Year)	</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/declaration_list_merged.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="#"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>    Land Use Certificate		</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="#"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/BuildingPlan.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>     College Building Plan	</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/BuildingPlan.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/AcademicCalender2022.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>      Academic Calendar		</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/AcademicCalender2022.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/NCTEOrder.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i>      NCTE ORDER		</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/NCTEOrder.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/balancesheet-21-22.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>    Balance sheet for the financial year 2021-22		</a></td>
								<td style={{ textAlign: 'center'}}><a target="_blank" download href="theme/web/images/downloads/balancesheet-21-22.pdf"><i class="fa fa-download" aria-hidden="true"></i></a></td>
							</tr>
							
							
						</tbody>
					</table>
                    </div>
                </div>
	        </div>
        </section></div>
 );
};

export default Downloads;