import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
const Header = () => {
    const [upcomingEvents, setUpcomingEvents] = useState([]); // Moved inside the component
    const [activitiesmenu, setActivitiesmenu] = useState([]); 
    const location =useLocation();
    console.log(location);
    useEffect(() => {
      axios.get('https://mahecooperativecollege.com/admin/api/upcoming-events')
        .then(response => {
          setUpcomingEvents(response.data.data);
          console.log(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching slider images:', error);
        });
        axios.get('https://mahecooperativecollege.com/admin/api/activities')
        .then(response => {
            setActivitiesmenu(response.data.data);
          console.log(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching slider images:', error);
        });
    }, []);


    // Rest of your Header component's code, JSX, etc.
    // You can use the 'upcomingEvents' state here

    return (
        
      <div className="Header">
        
        <header class=" site-header site-header__home-three ">
            <nav className="navbar fixed-top navbar-light header-navigation stricky">
                <div class="topbar-one container-fluid">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 topbar-one__left ">
                                <a href="mailto:mcctemahe@gmail.com"><i class="fa fa-envelope"> </i>  mcctemahe@gmail.com</a>
                                <a href="tel:+914902333975"><i class="fa fa-phone">   </i>    +91 9447193371  </a>
                            </div>
                            <div class="col-lg-2 topbar-one__right">
                                <div class="header__social">
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-facebook-square"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white logoheader container-fluid header-navigation">
                    <div class="container clearfix">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="logo-box clearfix">
                                    <Link class="navbar-brand"  to="/">
                                        <img src="/theme/web/images/logo.png" class="main-logo" alt="Awesome Image" />
                                    </Link>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid clearfix">
                    <div class="main-navigations collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navigation-box">
                            <li className={location.pathname === '/' ? 'current' : ''}><Link className="nav-link" to="/">HOME</Link></li>
                            <li className={location.pathname === '/about' || location.pathname ==='/principal-note' ? 'current' : ''}><a href="#!">PROFILE</a>
                                <ul class="sub-menu">
                                    <li><Link className="nav-link" to="/about">About Us</Link></li>
									<li><Link className="nav-link" to="/principal-note">Principal Note</Link></li>
                                </ul>
                            </li>
                             <li className={location.pathname === '/courses' ? 'current' : ''}><Link className="nav-link" to="/courses">COURSES</Link></li>
							 <li className={location.pathname === '/teaching-staff' || location.pathname ==='/nonteaching-staff' ? 'current' : ''}><a href="#!">STAFF</a>
                                <ul class="sub-menu">
                                    <li><Link className="nav-link" to="/teaching-staff">Teaching Staff</Link></li>
									<li><Link className="nav-link" to="/nonteaching-staff">Non-Teaching Staff</Link></li>
                                </ul>
                            </li>
							 <li className={location.pathname === '/facilities' ? 'current' : ''}><Link className="nav-link" to="/facilities">FACILITIES</Link></li>     
                            

                            <li className={location.pathname.startsWith('/activities') ? 'current' : ''}><a href="#!">Co curricular activities</a>
                                <ul class="sub-menu">
                                {activitiesmenu.map((mainmenu,index)=>{
            return(
                                    
									<li><Link className="nav-link"  to={mainmenu.sub_categories.length === 0 && `/activities/${mainmenu.id}/${mainmenu.category_slug}` } state={{categoreyname:mainmenu.categories}}>{mainmenu.categories}</Link>
                                    {mainmenu.sub_categories.length > 0 &&
                                    <ul className="sub-menu">
                                        {mainmenu.sub_categories.map((submenu,subindex)=>{
                                        return(
                                        <li>
                                            <Link className="nav-link" to={`/activities/${submenu.sub_id}/${submenu.subcategory_slug}`} state={{categoreyname:submenu.sub_name}}>{submenu.sub_name}</Link>
                                            
                                    </li>
                                     )
                                    }
                                    )}
                            </ul>
                            } 
                                    </li>
                                     )
                                    }
                                    )}
                                </ul>
                            </li>
                            <li><a href="#!">quality assurance</a>
                                <ul class="sub-menu">
                                    <li className={
                                    ['/iqac/iqac', '/iqac/iqac-reports', '/iqac/iqac-newsletter', '/iqac/iqac-minutesofmeeting', '/iqac/iqac-atr', '/iqac/bestpractices'
                                    , '/iqac/institutional-distinctiveness', '/iqac/strategic-plan', '/iqac/extension-activities', '/iqac/annual-report', '/iqac/college-magazine', '/iqac/aishe'
                                    , '/iqac/placement', '/iqac/mooc'].includes(location.pathname)
                                        ? 'current'
                                        : ''
                                        }><a href="#!">IQAC</a>
                                        <ul class="sub-menu">
                                            <li><Link className="nav-link" to="/iqac/iqac">IQAC</Link></li>
                                            <li><Link className="nav-link" to="/iqac/iqac-reports">IQAC Reports</Link></li>
                                            <li><Link className="nav-link" to="/iqac/iqac-newsletter">IQAC Newsletter </Link></li>
                                            <li><Link className="nav-link" to="/iqac/iqac-minutesofmeeting">IQAC Meeting Minutes</Link></li>
                                            <li><Link className="nav-link" to="/iqac/iqac-minutesofmeeting">IQAC Meeting Minutes</Link></li>
                                            <li><Link className="nav-link" to="/iqac/iqac-minutesofmeeting">IQAC Meeting Minutes</Link></li>
                                            <li><Link  className="nav-link"  to="/iqac/iqac-atr">IQAC ATR</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/bestpractices">Best Practices</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/institutional-distinctiveness">Institutional Distinctiveness</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/strategic-plan">Strategic Plan</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/extension-activities">Extension Activities</Link></li>
                                            <li><Link className="nav-link"  to="/iqac/annual-report">Annual Report</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/college-magazine">College Magazine</Link></li>
                                            <li><Link className="nav-link"  to="/iqac/aishe">AISHE</Link></li>
                                            <li><Link className="nav-link"  to="/iqac/placement">Placement</Link></li>
                                            <li><Link  className="nav-link" to="/iqac/mooc">MOOC</Link></li>
                                        </ul>
                                    </li>
                                    <li className={
                                    ['/naac/naac', '/naac/iiqa', '/naac/self-declaration', '/naac/under-taking', '/naac/ssr', '/naac/naac-reports'
                                    , '/naac/dvv', '/naac/student-satisfactory', '/naac/aqar'].includes(location.pathname)
                                        ? 'current'
                                        : ''
                                    }><a href="#!">NAAC</a>
                                        <ul class="sub-menu">
                                            <li><Link className="nav-link"  to="/naac/naac">NAAC</Link></li>
                                            <li><Link className="nav-link"  to="/naac/iiqa">IIQA</Link></li>
                                            <li><Link className="nav-link"  to="/naac/self-declaration">Self Declaration</Link></li>
                                            <li><Link className="nav-link"  to="/naac/under-taking">Under Taking </Link></li>
                                            <li><Link className="nav-link"  to="/naac/ssr">SSR</Link></li>
                                            <li><Link className="nav-link"  to="/naac/naac-reports">NAAC Reports</Link></li>
                                            <li><Link className="nav-link"  to="/naac/dvv">DVV </Link></li>
                                            <li><Link  className="nav-link" to="/naac/student-satisfactory">Student Satisfactory </Link></li>
                                            <li><Link className="nav-link"  to="/naac/aqar">AQAR </Link></li>
                                        </ul>
                                    </li>
                                    <li className={location.pathname === '/ncte/ncte' ||location.pathname === '/ncte/ncte-orders' || location.pathname ==='/ncte/par' ? 'current' : ''}><a href="#!">NCTC</a>
                                        <ul class="sub-menu">
                                            <li><Link className="nav-link"  to="/ncte/ncte">NCTC</Link></li>
                                            <li><Link className="nav-link" to="/ncte/ncte-orders">NCTE Orders</Link></li>
                                            <li><Link className="nav-link"  to="/ncte/par">PAR</Link></li>
                                        </ul>
                                    </li> 
                                    <li className={location.pathname === '/downloads' || location.pathname ==='/attendance' ? 'current' : ''}><a href="#!">Mandatory disclosures</a>
                                        <ul class="sub-menu">
                                            <li><Link className="nav-link"  to="/downloads">Downloads</Link></li>
                                            <li><Link className="nav-link"  to="/attendance">Attendance</Link></li>
                                        </ul>
                                    </li>
                                </ul>          
                            </li>
							
							{/* <li className={location.pathname === '/activities' ? 'current' : ''}><Link to="/activities">ACTIVITIES</Link></li> */}
							<li className={location.pathname === '//events/news_events' ? 'current' : ''}><Link className="nav-link"  to="/events/news_events">NEWS & EVENTS</Link></li>
                            {/* <li className={location.pathname === '/events/news_events' || location.pathname ==='/events/upcoming_events' ||/^\/events-detail\/.+$/.test(location.pathname) ? 'current' : ''}><a href="#!">EVENTS</a>
                                <ul class="sub-menu">
                                    <li><Link to="/events/news_events">NEWS & EVENTS</Link></li>
                                    <li><Link to="/events/upcoming_events">UPCOMING EVENTS</Link></li>
                                </ul>
                            </li> */}
							<li className={location.pathname === '/links' ? 'current' : ''}><Link className="nav-link"  to="/links">USEFUL LINKS</Link></li>
							<li className={location.pathname === '/admission' ? 'current' : ''}><Link className="nav-link"   to="/admission">ONLINE ADMISSION</Link></li>
                            <li className={location.pathname === '/contact' ? 'current' : ''}><Link className="nav-link"  to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    
                </div>
                
            </nav>
        </header>
        <div class="ticker">

<div class="news-title">

<h5> New Updates </h5>

</div>

<div class="news">

<marquee class="news-content">

  <p>{upcomingEvents.map((obj,index)=>{
            return(
                <span> {obj.short_description} </span> 
            
            )
        }
        )}
</p>

</marquee>

</div>

</div>
      </div>
    );
  }
  
  export default Header;