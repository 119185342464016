import React from "react";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import Home from "./component/home/Home";
import Aboutus from "./component/aboutus/Aboutus";
import Teachingstaff from "./component/teachingstaff/Teachingstaff";
import Nonteachingstaff from "./component/nonteachingstaff/Nonteachingstaff";
import Facilities from "./component/facilities/Facilities";
import Contact from "./component/contact/Contact";
import Activities from "./component/activities/Activities";
import Links from "./component/usefullinks/Usefullinks";
import Downloads from "./component/downloads/Downloads";
import Attendance from "./component/attendance/Attendance";
import Admission from "./component/admissionForm/Admissionform";
import Newsevents from "./component/news_events/Newsevents";
import Principalnote from "./component/principalnote/Principalnote";
import Courses from "./component/courses/Courses";
import Iqac from "./component/iqac/Iqac";
import Newseventsdetail from "./component/newseventsdetails/Newseventsdetail";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <Router>
      <Header />
      <Routes>
      <Route path="/" exact Component={Home} />
      <Route path="/about" Component={Aboutus} />
      <Route path="/teaching-staff" Component={Teachingstaff} />
      <Route path="/nonteaching-staff" Component={Nonteachingstaff} />
      <Route path="/facilities" Component={Facilities} />
      <Route path="/contact" Component={Contact} />
      <Route path="/activities/:id/:slug" Component={Activities} />
      <Route path="/links" Component={Links} />
      <Route path="/downloads" Component={Downloads} />
      <Route path="/attendance" Component={Attendance} />
      <Route path="/admission" Component={Admission} />
      <Route path="/events/:type" element={<Newsevents/>} />
      <Route path="/principal-note" Component={Principalnote} />
      <Route path="/courses" Component={Courses} />
      <Route path="/iqac/:type" element={<Iqac/>} />
      <Route path="/naac/:type" element={<Iqac/>}  />
      <Route path="/ncte/:type" element={<Iqac/>}/>
      <Route path="/events-detail/:id" element={<Newseventsdetail/>} />
     


      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
