import React, {useState,useEffect} from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
const Newsevents = () => {

    const [events,setEvents]=useState([]);
    const {type} = useParams(null);
    const [headtext,setHeadtext]=useState([]);
    useEffect(()=>{
        let isMounted = true;
        axios.get(`https://mahecooperativecollege.com/admin/api/events/${type}`)
        .then(function (response) {
            console.log(response);
            if (isMounted) {
                setEvents(response.data.data);
                if(type==='news_events')
                setHeadtext('NEWS')
                else
                setHeadtext('UPCOMING EVENTS')   
                
            }
        })
        .catch(function (error) {
            console.error(error);
            console.log("error");
        });

    return () => {
        isMounted = false;
    };
        
    },[type])

    return (
      <div className="newsevents">
        
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                   
                </ul>
                <h2 class="inner-banner__title">{headtext}</h2>
            </div>
        </section>
		
		<section class="blog-one blog-page">
            <div class="container">
                <div class="row">
                {events.map((obj,index)=>{
            return(
                    <div class="col-lg-4">
                        <div class="blog-one__single">
                            <div class="blog-one__image">
                                <img src={obj.images} alt="" width="500" height="230"/>
                                
                            </div>
                            <div class="blog-one__content text-center">
                               
                            <Link to={`/events-detail/${obj.id}`}>  <h2> {obj.title}</h2></Link>
                            <Link to={`/events-detail/${obj.id}`}><b className="text-dark">{obj.short_description}</b></Link>
                                
                                
                            </div>
                        </div>
                    </div>
                 )
                }
                )}	  
                  
                    
                    
                </div>
                {/* <div class="post-pagination">
                    <a href="#"><i class="fa fa-angle-double-left"></i></a>
                    <a class="active" href="#">1</a>
                    <a href="#">2</a>
                    <a href="#">3</a>
                    <a href="#">4</a>
                    <a href="#"><i class="fa fa-angle-double-right"></i></a>
                </div> */}
            </div>
        </section>






      </div>
    );
  }
  
  export default Newsevents;