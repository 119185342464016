import React from 'react';

const Attendance = () => {
  return (
	  <div>
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">Attendance</h2>
            </div>
        </section>
		
		
			 <section class="aboutus-one">
            <div class="container">
				<div class="row">
			        <div class="col-lg-6 col-sm-12 downloads">
					  <table class="table table-bordered">
						<tbody>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/BasicWorkDurationReport.pdf">  <i class="fa fa-file-pdf" aria-hidden="true"> </i> Weekly Status Report-Oct 21, 2019 to Oct 25, 2019</a></td>
							</tr>
						</tbody>
					  </table>
					</div>
					<div class="col-lg-6 col-sm-12 downloads">
					   <table class="table table-bordered">
						  <tbody>
							<tr>
								<td><a target="_blank" href="theme/web/images/downloads/BasicWorkDurationReport1.pdf"> <i class="fa fa-file-pdf" aria-hidden="true"> </i>    Weekly Status Report-Oct 29, 2019 to Nov 01, 2019</a></td>
							</tr>
						</tbody>
					  </table>
                    </div>
                </div>
	        </div>
        </section>
		</div>
 );
};

export default Attendance;
