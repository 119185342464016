import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery'; // Import jQuery//
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
//import './Home.css';
import 'owl.carousel'; // Import Owl Carousel
import TestimonialCarousel from '../Testimonials/TestimonialCarousel';
import Photogallery from '../photogallery/Photogallery';
const Home = () => {
    const [sliderImages, setSliderImages] = useState([]);
    useEffect(() => {
      
        axios.get('https://mahecooperativecollege.com/admin/api/slider')
          .then(response => {
          
            setSliderImages(response.data.data);
           console.log(response.data.data) 
          })
          .catch(error => {
            console.error('Error fetching slider images:', error);
          });
         
          return () => {
            $('.owl-carousel').trigger('destroy.owl.carousel');
          };
          
      }, []);
      
      
    return (
      <div className="Home">
        			<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

<ol class="carousel-indicators">

  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>

  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>

  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>

</ol>

<div class="carousel-inner">
  {sliderImages.map((item, index) => ( 
    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
      <img className="d-block w-100" src={item.image} alt={`Slide ${index}`}  />
    </div>
  ))}
</div>


<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">

  <span class="carousel-control-prev-icon" aria-hidden="true"></span>

  <span class="sr-only">Previous</span>

</a>

<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">

  <span class="carousel-control-next-icon" aria-hidden="true"></span>

  <span class="sr-only">Next</span>

</a>

</div>



<section class="cta-one cta-one__home-one" style={{backgroundImage: `url("assets/images/cta-bg-1-1.jpg")`}}>

<div class="container">
  <p>Mahe Co-operative College of Teacher Education a highly acclaimed Teacher Training College established on 2005. The College is recognized by National Council for Teacher Education (NCTE) vide order No. FPN-N/SRO/NCTE/2005-06/522 dt. 23.06.2005 and affiliated to Pondicherry University vide order No. Pu/As-1/Aca-1/MCCED/2012-13/255 dt. 07/11/2012. Not much B.Ed. Colleges are in vogue in the country under co-operative sector for imparting quality Teacher Education. It is an institution for molding the students to become the teachers of tomorrow. Located in an area free from pollution which encourages every student to concentrate on their studies. The college is located 500 Mts from St. Theresa Shrine, Main Road, Mahe and 1.5Kms from Mahe Railway Station. Easily accessable to every student by rail and road. The college is maintaining a high academic performance.</p>

  <div class="cta-one__btn-block">

      <h3>We offer B Ed. in </h3>

      <br/>

      <div class="row"> 

          <div class="col-lg-4">

              <div class="coursedetails">

                  <h4>PHYSICAL SCIENCE</h4>

              </div>

          </div>

          <div class="col-lg-4">

              <div class="coursedetails">

                  <h4>SOCIAL STUDIES</h4>

              </div>

          </div>

          <div class="col-lg-4">

              <div class="coursedetails">

                  <h4>BIOLOGICAL SCIENCE</h4>

              </div>

          </div>

          <div class="col-lg-4">

              <div class="coursedetails">

                  <h4>ENGLISH</h4>

              </div>

          </div>

          <div class="col-lg-4">

              <div class="coursedetails">

                  <h4>MATHEMATICS</h4>

              </div>

          </div>

          

      </div>

  </div>

</div>

</section>



<section class="team-tab">

<div class="container-fluid">



  <div class="tab-content">

      <div class="tab-pane show active  animated fadeInUp" role="tabpanel" id="overview">

          <div class="row">
          <div class="col-lg-1 p-0"></div>
              <div class="col-lg-5 p-0 d-flex">

                  <div class="my-auto">

                      <div class="team-tab__content">

                          <div class="team-tab__top">

                              <div class="cta-one__btn-block">

                                  <h3>Affiliations & Approvals </h3><br/>

                              </div>

                          </div>

                          

                          <div class="progress-one__wrap">

                              <div class="d-flex bg-light shadow-sm border-top rounded mb-4" style={{padding:20}}>

                                  <p class="m-0">Recognized by National Council for Teacher Education (NCTE) vide order No.<br/>
                                  <b> FPN-N/SRO/NCTE/2005-06/522 dt. 23.06.2005</b></p>

                              </div>

                              <div class="d-flex bg-light shadow-sm border-top rounded mb-4" style={{padding:20}}>

                                  <p class="m-0">Affiliated to Pondicherry University vide order No<br/>
                                  <b>Pu/As-1/ACA-1/MCCED/2012-13/255 dt. 07/11/2012</b></p>

                              </div>


                          </div>

                      </div>

                  </div>

              </div>

              <div class="col-lg-6 p-0">

                  <img src="theme/web/images/teacher-h3-1-1.jpg" alt="" className="img-fluid p-0 w-100" />

              </div>

          </div>

      </div>

     

  </div>

  

</div>

</section>









<section className="testimonials-one testimonials-one__home-three">

<div className="container">

  <div className="cta-one__btn-block text-center">

      <h3 className="block-title__title text-dark mb-3">Our students Say</h3>

  </div>

    
  <TestimonialCarousel />

</div>

</section>

<section class="blog-two">

<div class="container-fluid">

  <div class="cta-one__btn-block text-center">

      <h3 className="block-title__title text-white  mb-5">Our Photo Gallery</h3>

  </div>

  <Photogallery />
  

</div>

</section>
      </div>
    );
  }
  
  export default Home;