import React from "react";
import axios from "axios";
import { useState } from "react";

const Contact = () => {

    const [data,setData]= useState({
        name:"",
        mobile:"",
        email:"",
        message:""
    })
    const [responseMsg, setResponseMsg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const submit = (event) =>{
        event.preventDefault();
        // Perform the POST request
        axios.post('https://qupon.qa/mahecooperativecollege/api/save_contact', data)
          .then((response) => {
            // Handle success, if needed
            console.log(response.data);
            setResponseMsg(response.data.message);
            setErrorMessage('');
          })
          .catch((error) => {
            // Handle error, if needed
            console.error(error);
            setResponseMsg('');
            setErrorMessage(error.response.data.message);
          });

    }
    const handleIput = (event) =>{
        const newdata={...data}
        newdata[event.target.name]=event.target.value
        setData(newdata)
        

    }
    return (
      <div className="contact">
        
        <section class="inner-banner">
            <div class="container">
                <ul class="list-unstyled thm-breadcrumb">
                    
                </ul>
                <h2 class="inner-banner__title">Contact</h2>
            </div>
        </section>
		
		
		
	<section class="aboutus-one">
		<div class="container">
			<div class="row">
				<div class="col-sm-4">
					<h3>Contact Info</h3>
					<hr/>
          <p><b>MAHE CO-OPERATIVE COLLEGE OF TEACHER EDUCATION</b></p>
                                    <p>(A Unit of The Mahe Co-operative Centre for Information Technology Ltd. No. 689)
(Affiliated to Pondicherry University and Recognized by NCTE)
Cemetery Road, Manjakkal, MAHE – 673310, Puducherry State
</p>
                                    <p><a href="tel:9447193371"><i class="fa fa-mobile">  </i> +91 9447193371 ( President )</a></p>
                                    <p><a href="tel:0490 2333975"><i class="fa fa-phone">  </i>  0490 2333975 ( Office)</a></p>
                                    <p><a href="mailto:mcctemahe@gmail.com"><i class="fa fa-envelope">  </i> mcctemahe@gmail.com</a></p>



				</div>
					
				<div class="col-sm-8 contact-form">
				<h3>Contact Form</h3>
                {responseMsg && <p class="text-success">{responseMsg}</p>}
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
				<hr/>
				<form id="contact" method="post" class="form" role="form" onSubmit={(e)=>submit(e)}>
					<div class="row">
						<div class="col-xs-12 col-md-12 form-group">
							<input onChange={(e)=>handleIput(e)} value={data.name} class="form-control" id="name" name="name" placeholder="Name" type="text" required="" autofocus=""/>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-6 col-md-6 form-group">
							<input onChange={(e)=>handleIput(e)} value={data.mobile} class="form-control" id="Mobile" name="mobile" placeholder="Mobile Number" type="text" required="" autofocus=""/>
						</div>
						<div class="col-xs-6 col-md-6 form-group">
							<input onChange={(e)=>handleIput(e)} value={data.email} class="form-control" id="email" name="email" placeholder="Email" type="email" required=""/>
						</div>
					</div>    
					<textarea onChange={(e)=>handleIput(e)} value={data.message} class="form-control" id="message" name="message" placeholder="Message" rows="7"></textarea>
					<br/>
						
					<div class="row">
						<div class="col-xs-12 col-md-12 form-group">
							<button class="btn thm-btn pull-right" type="submit">Submit</button>
						</div>
					</div>  
				</form>
               </div>
            </div>
        </div>
        
	   
    </section>
  
  
	 <div class="container-fluid">
		<div class="row">
			<div class="col-sm-12">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3906.6601000795827!2d75.53734117848605!3d11.718469158824577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba42813b4e086b9%3A0xc7f1ea89c26d332c!2sMahe%20Co-Operative%20College!5e0!3m2!1sen!2sin!4v1683203584233!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
			</div>
        </div>
	 </div>


      </div>
    );
  }
  
  export default Contact;